@use "../utils" as *;

main {
  @include d-flex;
  min-height: 100%;
  @include flex-direction(column);

  p {
    font-weight: 300 !important;
  }

  .navbar {
    margin: 0 auto;
  }

  & > .core-container {
    @include flex(1 1 100%);
  }

  section {
    width: 100%;
    min-height: 100%;
  }

  #intro {
    .intro-container {
      margin-top: $default-distance * 2;

      & > .content {
        & > .social {
          margin-right: auto;
          margin-top: $default-distance * 2;
        }
      }
    }
  }

  .section-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    & > .section-header {
      margin: $default-distance-xl 0;
    }

    & > .section-content {
      & > .main-container {
        width: 100%;

        & > .content {
          width: 100%;
        }
      }
    }
  }
}
