@use "../utils" as *;

* + ol,
* + ul,
* + .MuiTypography-h6,
* + .MuiTypography-body2:not(li) {
  margin-top: $default-distance !important;
}

.MuiStack-root {
  .MuiTypography-body2:not(li) {
    margin-top: 0 !important;
  }
}

.MuiImage-root {
  & + .MuiImage-root {
    margin-top: $default-distance !important;
    margin-bottom: $default-distance !important;
  }
}

.MuiTypography-h4 + *,
.MuiTypography-h5 + *,
.MuiTypography-h6 + * {
  margin-top: $default-distance !important;
}

* + .MuiTypography-h5 {
  margin-top: $default-distance * 4 !important;
}

* + .MuiTypography-h4,
* + .MuiTypography-h6 {
  margin-top: $default-distance * 2 !important;
}
