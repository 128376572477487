@use "../utils" as *;

.activity-item {
  position: relative;

  & + .activity-item {
    margin-top: -1px;
  }

  & > .content {
    padding: $default-distance;
  }
}
