* {
  font-optical-sizing: auto;
  box-sizing: border-box;
  font-style: normal;
  outline: none;
  border: 0;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  min-height: 100%;
  position: relative;
}

body,
html {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

@import "assets/scss/default";
