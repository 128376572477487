.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.c-pointer {
  cursor: pointer;
}

.no-pointer {
  pointer-events: none;
}

.o-ellipsis {
  // display: block;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &.one {
    line-height: 1.8;
    max-height: 1.8em;
    -webkit-line-clamp: 1;
  }

  &.two {
    max-height: 3.2em;
    line-height: 1.6;
    -webkit-line-clamp: 2;
  }
}
