@use "../utils" as *;

.timeline {
  position: relative;

  &::before {
    top: 0;
    bottom: 0;
    width: 2px;
    content: "";
    position: absolute;
    left: $default-distance;
    margin-bottom: $default-distance * 2.5;
    background-color: var(--timeline-line-color);
  }

  .timeline-year-group {
    position: relative;
    padding-left: $default-distance * 0.2;
    margin-bottom: $default-distance * 1.5;

    & > *:not(:nth-child(1)),
    & > *:not(:nth-child(2)) {
      margin-top: -1px;
    }

    .timeline-year {
      @include d-flex;
      position: relative;
      @include align-items(center);
      margin-bottom: $default-distance;
      padding: $default-distance * 0.5 0;

      .timeline-icon {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin-right: $default-distance;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .activity-item {
      padding-left: $default-distance * 2.5;
    }
  }
}
